import { SzAlert, SzBox, SzButton, SzInput } from "@suezenv/react-theme-components";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { routes } from "../../../config";
import { contactLabel } from "../../../main/utils";
import ContactAutoCompleteItemsComponent from "../../components/contactAutoCompleteItemsComponent";
import ContactService from "../../services/ContactService";
import ZacService from "../../services/ZacService";
import { autocompleteAction } from "../../store/actions";
import zacAction from "../../store/actions/zacAction";
import { createZacModel, getFormatedForm } from "../models";

interface ICreateZacForm {
  contractId: string;
  contactAutocomplete: any;
  setContactAutocomplete: (payload: any) => void;
  setZacList: (payload: any) => void;
  saveAction: (payload: any) => void;
  geoJson: any;
  zacDetail?: any;
  redirect: boolean;
  cancel?: () => void;
}

const ZacForm = (props: ICreateZacForm) => {
  const NBR_CHAR_START_AUTO_COMPLETE = 4;
  const history = useHistory();
  const { zacDetail } = props;
  const initialState = {
    name: "",
    area: "",
    contact: "",
    autocompleteContact: "",
    isValid: false,
    errors: false,
  };
  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (zacDetail) {
      const zacValues = {
        name: zacDetail.name || "",
        area: zacDetail.area || "",
        contact: zacDetail.contact ? zacDetail.contact.id : "",
        autocompleteContact: zacDetail.contact ? zacDetail.contact.fullName : "",
      };

      setState({ ...initialState, ...zacValues });
    }
  }, [zacDetail, state]);

  const { t } = useTranslation();
  const [schema] = getFormatedForm(createZacModel);
  const { contractId, setContactAutocomplete, contactAutocomplete, geoJson, redirect, saveAction } = props;
  const { name, area, autocompleteContact }: any = createZacModel;

  const saveNewZac = () => {
    ZacService.saveNewZac(contractId, { ...state, geoJson }).then((response) => {
      if (response.data.errors) {
        setState({ ...state, errors: true });
      } else if (redirect) {
        ZacService.getZacs(contractId).then((response) => {
          props.setZacList(response);
        });
        history.push(routes.zac);
      } else {
        saveAction(response.data);
      }
    });
  };

  const editZac = () => {
    ZacService.editZac(contractId, zacDetail.id, { ...state, geoJson }).then((response) => {
      if (response.data.errors) {
        setState({ ...state, errors: true });
      } else {
        ZacService.getZacs(contractId).then((response) => {
          props.setZacList(response);
        });
        history.push(routes.zac);
      }
    });
  };

  return (
    <>
      {state.errors && <SzAlert variant="danger">{t("forms:An_error_has_occurred")}</SzAlert>}
      <Formik
        validationSchema={schema}
        onSubmit={() => {}}
        initialValues={state}
        validateOnBlur={false}
        validateOnChange={false}
        autocomplete="off"
      >
        {({ submitForm, handleChange, errors, setFieldError, setErrors }) => {
          schema.isValid(state).then((isValidForm: any) => {
            if (isValidForm !== state.isValid) {
              setErrors({});
              setState({ ...state, isValid: isValidForm });
            }
          });
          const changeHandle = (e: any) => {
            setState({ ...state, [e.target.name]: e.target.value });
          };
          const customHandleBlur = (e: any) => {
            validateTarget(e.target.name, e.target.value);
          };
          const validateTarget = (targetName: string, value: string) => {
            schema
              .validateAt(targetName, { ...state, [targetName]: value })
              .then(() => {
                const newErrors = { ...errors };
                deleteFromObject(targetName, newErrors);
                setErrors(newErrors);
              })
              .catch((error: any) => {
                const { path, message } = error;
                setFieldError(path, message);
              });
          };

          const deleteFromObject = (toDeleteKey: string, object: any) => {
            for (const index in object) {
              if (index === toDeleteKey) {
                delete object[index];
              }
            }
          };
          const contactAutoCompleteHandle = (e: any) => {
            const value = e.target.value;
            setState({ ...state, autocompleteContact: value, contact: "" });
            if (e.target.value.length >= NBR_CHAR_START_AUTO_COMPLETE) {
              ContactService.searchContact(contractId, value).then((data: any) => {
                setContactAutocomplete(data);
              });
            } else {
              setContactAutocomplete([]);
            }
          };

          const contactAutoCompleteClickHandle = (
            id: string,
            firstName: string,
            lastName: string,
            socialReason: string,
            isRealPerson: boolean,
          ) => {
            if (id) {
              const selectedContact = { id, firstName, lastName, socialReason, isMain: false, isRealPerson };
              setState({ ...state, autocompleteContact: contactLabel(selectedContact), contact: id });
            }
            setContactAutocomplete([]);
          };
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (!state.isValid) {
                  submitForm();
                } else {
                  if (zacDetail) {
                    editZac();
                  } else {
                    saveNewZac();
                  }
                }
              }}
            >
              <SzBox className="row box create-contact" tag="div">
                <div className="col">
                  <div className="pb-0 required-div">
                    <SzInput
                      className="mb-0"
                      onChange={changeHandle}
                      onBlur={customHandleBlur}
                      label={t(name.label)}
                      name={name.name}
                      required={name.required}
                      placeholder={t(name.placeholder)}
                      type={name.type}
                      value={state.name}
                    />
                    {errors.name && (
                      <SzAlert variant="danger">
                        <>{t(`forms:${errors.name}`)}</>
                      </SzAlert>
                    )}
                  </div>
                  <div className="pb-0">
                    <SzInput
                      className="mb-0"
                      onChange={changeHandle}
                      onBlur={customHandleBlur}
                      label={t(area.label)}
                      name={area.name}
                      required={area.required}
                      placeholder={t(area.placeholder)}
                      type={area.type}
                      value={state.area}
                    />
                    {errors.area && (
                      <SzAlert variant="danger">
                        <>{t(`forms:${errors.area}`)}</>
                      </SzAlert>
                    )}
                  </div>
                  <SzBox className="row mt-5 mb-4 m-0 box" tag="div">
                    <div className="p-0 col-12">
                      <SzInput
                        className="mb-0"
                        onChange={contactAutoCompleteHandle}
                        onBlur={customHandleBlur}
                        label={t("forms:zac.contact")}
                        name={autocompleteContact.name}
                        required={autocompleteContact.required}
                        placeholder={t(autocompleteContact.placeholder)}
                        type={autocompleteContact.type}
                        value={state.autocompleteContact}
                        icon="search"
                      />
                    </div>
                    {contactAutocomplete && (
                      <ContactAutoCompleteItemsComponent
                        className={"contact-auto-complete-zac"}
                        contactAutoComplete={{ items: contactAutocomplete }}
                        contactAutoCompleteClickHandle={contactAutoCompleteClickHandle}
                      />
                    )}
                  </SzBox>
                </div>
              </SzBox>
              <div className="w-100 text-center">
                <SzButton
                  variant="secondary"
                  onClick={() => {
                    if (typeof props.cancel !== "undefined") {
                      props.cancel();
                    } else {
                      history.push("/zac");
                    }
                  }}
                  className="cancel-zac mr-2"
                >
                  {t("forms:zac.cancel")}
                </SzButton>
                <SzButton type="submit" className="add-zac" isDisabled={!state.isValid}>
                  {t("forms:zac.add")}
                </SzButton>
              </div>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    contactAutocomplete: state.autocomplete.contact,
  };
};
const mapDispatchToProps = {
  setContactAutocomplete: autocompleteAction.setContactAutocomplete,
  setZacList: zacAction.setZacList,
};

export default connect(mapStateToProps, mapDispatchToProps)(ZacForm);
